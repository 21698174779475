/* Fondo negro para todo el body */
body {
  background-color: black;
}

/* Estilos principales del contenedor */
.container {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Estilos para la cabecera */
.header {
  margin-bottom: 20px;
}

.servicio {
  font-size: 28px;
  color: white;
  margin: 0;
}

.especializado {
  font-size: 28px;
  color: #007BFF;
  margin: 0;
}

.logo {
  display: block;
  margin: 10px auto;
  width: auto;
  height: 50px;
}

/* Estilos para el formulario */
.service-form {
  background-color: #cfe2ff;
  padding: 20px;
  border-radius: 16px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

h2 {
  font-size: 18px;
  color: #2c3e50;
  margin-bottom: 20px;
}

.form-row-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  flex: 1 1 calc(33% - 10px);
}

.form-row label {
  font-size: 14px;
  white-space: nowrap;
  color: #2c3e50;
}

.form-row input {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  flex: 1;
  color: black; /* Asegura que el texto sea visible */
  background-color: white; /* Fondo blanco para que sea visible en el PDF */
}

.form-total {
  text-align: right;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #2980b9;
}

/* Estilo para el botón de PDF */
.pdf-button {
  margin-top: 20px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  background-color: #2980b9;
  color: white;
  border: none;
  border-radius: 5px;
}

.pdf-button:hover {
  background-color: #1a5276;
}

/* Estilos para el contenedor de PDF */
.pdf-display {
  display: none;
  background-color: black;
  color: white;
  padding: 20px;
  border-radius: 16px;
}

/* Mostrar el contenedor del PDF en la captura */
@media print {
  .pdf-display {
    display: block;
  }

  .service-form {
    display: none;
  }
}

/* Estilos para móviles */
@media (max-width: 600px) {
  .form-row-group {
    flex-direction: column;
  }

  .service-form {
    padding: 15px;
    max-width: 100%;
  }

  .form-row input {
    font-size: 12px;
  }

  .pdf-button {
    font-size: 12px;
    padding: 8px 12px;
  }
}
